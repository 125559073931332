/*--------------------------------------------------------------------------
   #sec-price
---------------------------------------------------------------------------*/
.sec-price {

	@include mq-sp {
	}

	.section__img {
		img {
			@include mq-sp {
				width: 81px;
			}
		}
	}
	
	&__btn.c-btn-basic {
		margin-top: 136px;

		@include mq-sp {
			margin-top: 70px;
		}
	}
}
