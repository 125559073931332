/*--------------------------------------------------------------------------
   #sec-mv
---------------------------------------------------------------------------*/
.sec-mv {
	position: relative;

	@include mq-sp {
	}

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.7);
		// content: '';
	}

	&__body {
		position: relative;
    min-height: calc(768px - 122px);
    height: calc(100vh - 122px);
    overflow: hidden;

		@include mq-sp {
      min-height: 480px;
			height: calc(100vh - 90px);
		}
	}

	&__hdg {
		position: absolute;
		top: 50%;
		left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

		@include mq-sp {
			img {
				width: 186px;
			}
		}
	}

	&__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
		height: 100%;
		background: url("../../img/index/mv_img.jpg") no-repeat 50% 50%;
    background-size: cover;
    transform: scale(1);

		@include mq-sp {
			background: url("../../img/index/mv_img_sp.jpg") no-repeat 50% 50%;
			background-size: cover;
		}

		img {
			display: none;
		}
	}
}
