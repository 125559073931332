/*--------------------------------------------------------------------------
   #sec-access
---------------------------------------------------------------------------*/
.sec-access {

	@include mq-sp {
	}

	.section__img {
		img {
			width: 125px;
			height: 125px;
			opacity: 0.7;

			@include mq-sp {
				width: 93px;
				height: auto;
			}
		}
	}
	
	&__btn.c-btn-basic {
		margin-top: 128px;

		@include mq-sp {
			margin-top: 64px;
		}
	}
}
