/*--------------------------------------------------------------------------
   #sec-studio
---------------------------------------------------------------------------*/
.sec-studio {

	@include mq-sp {
	}
	
	&__list {
		display: flex;
		justify-content: space-between;

		@include mq-sp {
			display: block;
		}
	}

	&__item {
		width: 460px;
		margin-top: 90px;
		text-align: center;

		@include mq-sp {
			width: auto;
			margin-top: 55px;
		}

		.img > img {
			width: 100%;
		}

		.name {
			margin-top: 40px;
			text-align: center;

			@include mq-sp {
				margin-top: 33px;
			}
		}
	}
	
	&__btn.c-btn-basic {
		margin-top: 67px;

		@include mq-sp {
			margin-top: 82px;
		}
	}
}
