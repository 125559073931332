/*--------------------------------------------------------------------------
   common
---------------------------------------------------------------------------*/
.section {
	position: relative;
	padding: 170px 0 180px;

	@include mq-sp {
		padding: 100px 0 80px;
	}

	&--even {
		background-color: #F7F7F7;

		.c-btn-basic a {
			background-color: #F7F7F7;
		}
	}

	&__hdg {
		margin-bottom: 135px;
		font-family: $font-family-en;
		font-size: 46px;
		font-weight: 300;
		line-height: 1;
		text-align: center;

		@include mq-sp {
			margin-bottom: 50px;
			font-size: 34px;
		}
	}

	&__lead {
		line-height: 2.57;
		text-align: center;

		@include mq-sp {
			font-size: 14px;
			line-height: 2;
			text-align: left;
		}
	}

	&__img {
		margin-top: 108px;
		text-align: center;

		@include mq-sp {
			margin-top: 55px;
		}
	}

	.c-btn-basic {
		margin-top: 100px;

		@include mq-sp {
			margin-top: 50px;
		}
	}
}

.bnr-wanted {
  margin-top: 15px;
  text-align: center;
  opacity: 1;
  transition: opacity .4s;

  @include mq-sp {
    margin-top: 0;
  }

  &:hover {
    opacity: .6;
  }

  img {
    max-width: 500px;
    width: 100%;
    height: auto;
  }
}
