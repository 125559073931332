/*--------------------------------------------------------------------------
   #sec-reserve
---------------------------------------------------------------------------*/
.sec-reserve {

	@include mq-sp {
	}

	.section__img {
		img {
			@include mq-sp {
				width: 94px;
			}
		}
	}
	
	&__btn.c-btn-basic {
		margin-top: 136px;

		@include mq-sp {
			margin-top: 91px;
		}
	}
}
