/*--------------------------------------------------------------------------
   #sec-contact
---------------------------------------------------------------------------*/
.sec-contact {

	@include mq-sp {
	}

	.section__img {
		img {
			@include mq-sp {
				width: 86px;
			}
		}
	}
	
	&__btn.c-btn-basic {
		margin-top: 143px;

		@include mq-sp {
			margin-top: 72px;
		}
	}
}
