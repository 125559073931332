/*--------------------------------------------------------------------------
   overwrite
---------------------------------------------------------------------------*/
.g-header h1.g-header__logo {
	display: block;
}
.g-header p.g-header__logo {
	display: none;
}

.l-page__main {
	padding-bottom: 40px;

	@include mq-sp {
		padding-bottom: 0;
	}
}
