/*--------------------------------------------------------------------------
   custom animation
---------------------------------------------------------------------------*/

/* sec-mv
=================================================================*/
// init
.sec-mv {
  &__hdg img {
    opacity: 0;
  }

  &__item {
    transform: scale(1.2);
  }

}

// animated
.sec-mv.is-animated {
  .sec-mv__hdg img {
    opacity: 1;
    transition: opacity 1s ease-in-out;
    transition-delay: .6s;
  }

  .sec-mv__item {
    transform: scale(1);
    transition: transform 6s $ease-out-quad;
    transition-delay: 0s;
  }
}
