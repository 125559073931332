/*--------------------------------------------------------------------------
   #sec-company
---------------------------------------------------------------------------*/
.sec-company {

	@include mq-sp {
	}

	.section__img {
		img {
			@include mq-sp {
				width: 91px;
			}
		}
	}
	
	&__btn.c-btn-basic {
		margin-top: 136px;

		@include mq-sp {
			margin-top: 80px;
		}
	}
}
