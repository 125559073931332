/*==========================================================================

   mixin

===========================================================================*/
/*--------------------------------------------------------------------------
   overwrite
---------------------------------------------------------------------------*/
.g-header h1.g-header__logo {
  display: block;
}

.g-header p.g-header__logo {
  display: none;
}

.l-page__main {
  padding-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .l-page__main {
    padding-bottom: 0;
  }
}

/*--------------------------------------------------------------------------
   common
---------------------------------------------------------------------------*/
.section {
  position: relative;
  padding: 170px 0 180px;
}
@media only screen and (max-width: 767px) {
  .section {
    padding: 100px 0 80px;
  }
}
.section--even {
  background-color: #F7F7F7;
}
.section--even .c-btn-basic a {
  background-color: #F7F7F7;
}
.section__hdg {
  margin-bottom: 135px;
  font-family: urw-din, sans-serif;
  font-size: 46px;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .section__hdg {
    margin-bottom: 50px;
    font-size: 34px;
  }
}
.section__lead {
  line-height: 2.57;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .section__lead {
    font-size: 14px;
    line-height: 2;
    text-align: left;
  }
}
.section__img {
  margin-top: 108px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .section__img {
    margin-top: 55px;
  }
}
.section .c-btn-basic {
  margin-top: 100px;
}
@media only screen and (max-width: 767px) {
  .section .c-btn-basic {
    margin-top: 50px;
  }
}

.bnr-wanted {
  margin-top: 15px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.4s;
}
@media only screen and (max-width: 767px) {
  .bnr-wanted {
    margin-top: 0;
  }
}
.bnr-wanted:hover {
  opacity: 0.6;
}
.bnr-wanted img {
  max-width: 500px;
  width: 100%;
  height: auto;
}

/*--------------------------------------------------------------------------
   #sec-mv
---------------------------------------------------------------------------*/
.sec-mv {
  position: relative;
}
.sec-mv::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}
.sec-mv__body {
  position: relative;
  min-height: 646px;
  height: calc(100vh - 122px);
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .sec-mv__body {
    min-height: 480px;
    height: calc(100vh - 90px);
  }
}
.sec-mv__hdg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sec-mv__hdg img {
    width: 186px;
  }
}
.sec-mv__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../img/index/mv_img.jpg") no-repeat 50% 50%;
  background-size: cover;
  transform: scale(1);
}
@media only screen and (max-width: 767px) {
  .sec-mv__item {
    background: url("../../img/index/mv_img_sp.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
}
.sec-mv__item img {
  display: none;
}

/*--------------------------------------------------------------------------
   #sec-information
---------------------------------------------------------------------------*/
.sec-information {
  position: relative;
  padding-top: 210px;
}
@media only screen and (max-width: 767px) {
  .sec-information {
    padding-top: 85px;
  }
}
.sec-information__hdg {
  margin-bottom: 96px;
}
@media only screen and (max-width: 767px) {
  .sec-information__hdg {
    margin-bottom: 55px;
  }
}
.sec-information__list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .sec-information__list {
    display: block;
    font-size: 14px;
  }
}
.sec-information__list-date {
  width: 150px;
  line-height: 2.57;
}
@media only screen and (max-width: 767px) {
  .sec-information__list-date {
    width: auto;
    line-height: inherit;
  }
}
.sec-information__list-date:not(:first-of-type) {
  margin-top: 30px;
}
.sec-information__list-txt {
  width: calc(100% - 150px);
  line-height: 2.57;
}
@media only screen and (max-width: 767px) {
  .sec-information__list-txt {
    width: auto;
    line-height: 2;
  }
}
.sec-information__list-txt:not(:first-of-type) {
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  .sec-information__list-txt:not(:first-of-type) {
    margin-top: 4px;
  }
}
.sec-information__list-txt a {
  text-decoration: underline;
}
.mode-pc .sec-information__list-txt a:hover {
  text-decoration: none;
}

/*--------------------------------------------------------------------------
   #sec-point
---------------------------------------------------------------------------*/
.sec-point__list {
  display: flex;
  flex-wrap: wrap;
}
.sec-point__list .point {
  position: relative;
  width: 260px;
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .sec-point__list .point {
    width: 44.7%;
    margin-left: 0;
  }
}
.sec-point__list .point:nth-child(3n+1) {
  margin-left: 0;
}
.sec-point__list .point:nth-child(n+4) {
  margin-top: 70px;
}
@media only screen and (max-width: 767px) {
  .sec-point__list .point:nth-child(n+4) {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-point__list .point:nth-child(even) {
    margin-left: auto;
  }
}
@media only screen and (max-width: 767px) {
  .sec-point__list .point:nth-child(n+3) {
    margin-top: 50px;
  }
}
.sec-point__list .point__ico {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 90px;
}
@media only screen and (max-width: 767px) {
  .sec-point__list .point__ico {
    height: 65px;
  }
  .sec-point__list .point__ico img {
    transform: scale(0.7);
  }
}
.sec-point__list .point__hdg {
  margin-top: 30px;
  font-family: urw-din, sans-serif;
  font-size: 28px;
  font-weight: 300;
}
@media only screen and (max-width: 767px) {
  .sec-point__list .point__hdg {
    margin-top: 10px;
    font-size: 23px;
  }
}
.sec-point__list .point__txt {
  margin-top: 10px;
  line-height: 2.57;
}
@media only screen and (max-width: 767px) {
  .sec-point__list .point__txt {
    font-size: 14px;
    line-height: 1.7;
  }
}

/*--------------------------------------------------------------------------
   #sec-studio
---------------------------------------------------------------------------*/
.sec-studio__list {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .sec-studio__list {
    display: block;
  }
}
.sec-studio__item {
  width: 460px;
  margin-top: 90px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-studio__item {
    width: auto;
    margin-top: 55px;
  }
}
.sec-studio__item .img > img {
  width: 100%;
}
.sec-studio__item .name {
  margin-top: 40px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-studio__item .name {
    margin-top: 33px;
  }
}
.sec-studio__btn.c-btn-basic {
  margin-top: 67px;
}
@media only screen and (max-width: 767px) {
  .sec-studio__btn.c-btn-basic {
    margin-top: 82px;
  }
}

/*--------------------------------------------------------------------------
   #sec-equipment
---------------------------------------------------------------------------*/
.sec-equipment__img {
  margin-top: 100px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-equipment__img {
    margin-top: 70px;
  }
  .sec-equipment__img img {
    width: 100%;
  }
}
.sec-equipment__btn.c-btn-basic {
  margin-top: 145px;
}
@media only screen and (max-width: 767px) {
  .sec-equipment__btn.c-btn-basic {
    margin-top: 78px;
  }
}

/*--------------------------------------------------------------------------
   #sec-price
---------------------------------------------------------------------------*/
@media only screen and (max-width: 767px) {
  .sec-price .section__img img {
    width: 81px;
  }
}
.sec-price__btn.c-btn-basic {
  margin-top: 136px;
}
@media only screen and (max-width: 767px) {
  .sec-price__btn.c-btn-basic {
    margin-top: 70px;
  }
}

/*--------------------------------------------------------------------------
   #sec-access
---------------------------------------------------------------------------*/
.sec-access .section__img img {
  width: 125px;
  height: 125px;
  opacity: 0.7;
}
@media only screen and (max-width: 767px) {
  .sec-access .section__img img {
    width: 93px;
    height: auto;
  }
}
.sec-access__btn.c-btn-basic {
  margin-top: 128px;
}
@media only screen and (max-width: 767px) {
  .sec-access__btn.c-btn-basic {
    margin-top: 64px;
  }
}

/*--------------------------------------------------------------------------
   #sec-reserve
---------------------------------------------------------------------------*/
@media only screen and (max-width: 767px) {
  .sec-reserve .section__img img {
    width: 94px;
  }
}
.sec-reserve__btn.c-btn-basic {
  margin-top: 136px;
}
@media only screen and (max-width: 767px) {
  .sec-reserve__btn.c-btn-basic {
    margin-top: 91px;
  }
}

/*--------------------------------------------------------------------------
   #sec-contact
---------------------------------------------------------------------------*/
@media only screen and (max-width: 767px) {
  .sec-contact .section__img img {
    width: 86px;
  }
}
.sec-contact__btn.c-btn-basic {
  margin-top: 143px;
}
@media only screen and (max-width: 767px) {
  .sec-contact__btn.c-btn-basic {
    margin-top: 72px;
  }
}

/*--------------------------------------------------------------------------
   #sec-company
---------------------------------------------------------------------------*/
@media only screen and (max-width: 767px) {
  .sec-company .section__img img {
    width: 91px;
  }
}
.sec-company__btn.c-btn-basic {
  margin-top: 136px;
}
@media only screen and (max-width: 767px) {
  .sec-company__btn.c-btn-basic {
    margin-top: 80px;
  }
}

/*--------------------------------------------------------------------------
   custom animation
---------------------------------------------------------------------------*/
/* sec-mv
=================================================================*/
.sec-mv__hdg img {
  opacity: 0;
}
.sec-mv__item {
  transform: scale(1.2);
}

.sec-mv.is-animated .sec-mv__hdg img {
  opacity: 1;
  transition: opacity 1s ease-in-out;
  transition-delay: 0.6s;
}
.sec-mv.is-animated .sec-mv__item {
  transform: scale(1);
  transition: transform 6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-delay: 0s;
}