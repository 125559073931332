/*--------------------------------------------------------------------------
   #sec-information
---------------------------------------------------------------------------*/
.sec-information {
	position: relative;
	padding-top: 210px;

	@include mq-sp {
		padding-top: 85px;
	}
	
	&__hdg {
		margin-bottom: 96px;

		@include mq-sp {
			margin-bottom: 55px;
		}
	}
	
	&__list {
		display: flex;
		flex-wrap: wrap;

		@include mq-sp {
			display: block;
			font-size: 14px;
		}
	}
	
	&__list-date {
		width: 150px;
		line-height: 2.57;

		@include mq-sp {
			width: auto;
			line-height: inherit;
		}
		
		&:not(:first-of-type) {
			margin-top: 30px;
		}
	}
	
	&__list-txt {
		width: calc(100% - 150px);
		line-height: 2.57;

		@include mq-sp {
			width: auto;
			line-height: 2;
		}
		
		&:not(:first-of-type) {
			margin-top: 30px;

			@include mq-sp {
				margin-top: 4px;
			}
		}
		
		a {
			text-decoration: underline;
			
			.mode-pc &:hover {
				text-decoration: none;
			}
		}
	}
}
