/*--------------------------------------------------------------------------
   #sec-equipment
---------------------------------------------------------------------------*/
.sec-equipment {

	@include mq-sp {
	}

	&__img {
		margin-top: 100px;
		text-align: center;

		@include mq-sp {
			margin-top: 70px;

			img {
				width: 100%;
			}
		}
	}
	
	&__btn.c-btn-basic {
		margin-top: 145px;

		@include mq-sp {
			margin-top: 78px;
		}
	}
}
