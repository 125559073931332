/*--------------------------------------------------------------------------
   #sec-point
---------------------------------------------------------------------------*/
.sec-point {

	@include mq-sp {
	}
	
	&__list {
		display: flex;
		flex-wrap: wrap;

		@include mq-sp {
		}
		
		.point {
			position: relative;
			width: 260px;
			margin-left: auto;

			@include mq-sp {
				width: 44.7%;
				margin-left: 0;
			}
			
			&:nth-child(3n + 1) {
				margin-left: 0;
			}
			
			&:nth-child(n + 4) {
				margin-top: 70px;

				@include mq-sp {
					margin-top: 40px;
				}
			}
			
			&:nth-child(even) {
				@include mq-sp {
					margin-left: auto;
				}
			}
			
			&:nth-child(n + 3) {
				@include mq-sp {
					margin-top: 50px;
				}
			}
			
			&__ico {
				display: flex;
				align-items: flex-end;
				justify-content: center;
				height: 90px;

				@include mq-sp {
					height: 65px;
					
					img {
						transform: scale(0.7);
					}
				}
			}
			
			&__hdg {
				margin-top: 30px;
				font-family: $font-family-en;
				font-size: 28px;
				font-weight: 300;

				@include mq-sp {
					margin-top: 10px;
					font-size: 23px;
				}
			}
			
			&__txt {
				margin-top: 10px;
				line-height: 2.57;

				@include mq-sp {
					font-size: 14px;
					line-height: 1.7;
				}
			}
		}
	}
}
